import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import ButtonToggle from './ButtonToggle';
import Highlight from 'react-highlighter';
import SearchDropdownMenu from './SearchDropdownMenu';

export default function MultiSelectDropdown({
  placeholder,
  label,
  icon,
  options = [],
  selectedOptions = [],
  onChange,
}) {
  const [show, setShow] = useState(false);
  return (
    <Dropdown
      show={show}
      onToggle={(isOpen, event, metadata) => {
        if (metadata.source !== 'select') {
          setShow(isOpen);
        }
      }}
    >
      <Dropdown.Toggle as={ButtonToggle} iconRight={icon}>
        <div className="dropdown-content-btn">
          <span className="label">{label}</span>
          <span className="text-selected">{placeholder}</span>
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu
        as={MultiSelectDropdownMenu}
        options={options}
        selectedOptions={selectedOptions}
        onChange={onChange}
      ></Dropdown.Menu>
    </Dropdown>
  );
}

const MultiSelectDropdownMenu = React.forwardRef(function MultiSelectDropdownMenu(
  { options, style, className, 'aria-labelledby': labeledBy, selectedOptions, onChange },
  ref,
) {
  const [value, setValue] = useState('');

  return (
    <SearchDropdownMenu
      innerRef={ref}
      style={style}
      className={className}
      aria-labelledby={labeledBy}
      value={value}
      setValue={setValue}
    >
      {options
        .filter((option) => !value || option.name.toLowerCase().startsWith(value.toLowerCase()))
        .map((option) => (
          <div key={option.id} className="d-flex align-items-center py-2 check">
            <input
              checked={selectedOptions.some((selectedOption) => selectedOption.id == option.id)}
              type="checkbox"
              id={option.id + option.name}
              onChange={(e) => {
                let newSelectedOptions = selectedOptions.filter(
                  (selectedOption) => selectedOption.id != option.id,
                );
                if (e.target.checked) {
                  newSelectedOptions.push(option);
                }
                onChange(newSelectedOptions);
              }}
            />
            <label htmlFor={option.id + option.name}>
              <Highlight search={value}>{option.name}</Highlight>
            </label>
          </div>
        ))}
    </SearchDropdownMenu>
  );
});
