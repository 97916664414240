import React from 'react';

const Select = ({ options, onChange, value, label }) => {
  const handleChange = (event) => {
    const value = getValue(event);
    onChange && onChange(value);
  };

  function getValue(event) {
    const value = parseInt(event.target.value);
    return Number.isInteger(value) ? value : '';
  }

  return (
    <div className="select-component">
      <select value={value} onChange={handleChange}>
        <option value="">{label}</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
