import { translate } from '../data/languages/languageUtils';

const useFilters = (language) => {
  const moreText = translate('common.more', language);

  const bedroomsOptions = [
    { text: `1 ${moreText}`, value: 1 },
    { text: `2 ${moreText}`, value: 2 },
    { text: `3 ${moreText}`, value: 3 },
    { text: `4 ${moreText}`, value: 4 },
    { text: `5 ${moreText}`, value: 5 },
    { text: `6 ${moreText}`, value: 6 },
    { text: `7 ${moreText}`, value: 7 },
    { text: `8 ${moreText}`, value: 8 },
    { text: `9 ${moreText}`, value: 9 },
    { text: `10 ${moreText}`, value: 10 },
  ];

  const pricesOptions = [
    { text: '500k', value: 500000 },
    { text: '1M', value: 1000000 },
    { text: '1.5M', value: 1500000 },
    { text: '2M', value: 2000000 },
    { text: '3M', value: 3000000 },
    { text: '4M', value: 4000000 },
    { text: '5M', value: 5000000 },
    { text: '6M', value: 6000000 },
    { text: '8M', value: 8000000 },
    { text: '10M', value: 10000000 },
  ];

  const filtersSubscribe = {
    propertyFeatures: [],
    propertyTypes: [],
    propertyRegions: [],
    propertyLocations: [],
    bedroomsMin: 0,
    price: {},
  };

  const getTexts = (filtersSelected, AllFilters, language) => {
    const { propertyTypes, propertyLocations, propertyRegions, propertyFeatures } = AllFilters;
    const locations = getItemsSelected(filtersSelected.propertyLocations, propertyLocations);
    const properties = getItemsSelected(filtersSelected.propertyTypes, propertyTypes);
    const features = getItemsSelected(filtersSelected.propertyFeatures, propertyFeatures);
    const regions = getItemsSelected(filtersSelected.propertyRegions, propertyRegions);

    const propertiesTextLang = translate('typeFilters.properties', language);
    const regionsTextLang = translate('typeFilters.regions', language);
    const locationsTextLang = translate('typeFilters.locations', language);
    const featuresTextLang = translate('typeFilters.features', language);
    const bedroomsLang = translate('typeFilters.bedrooms', language);
    const priceTextLang = translate('typeFilters.price', language);

    const propertiesText = properties.length > 0 ? `${propertiesTextLang}: ${properties}` : '';
    const locationsText = locations.length > 0 ? `${locationsTextLang}: ${locations}` : '';
    const regionsText = regions.length > 0 ? `${regionsTextLang}: ${regions}` : '';
    const featuresText = features.length > 0 ? `${featuresTextLang}: ${features}` : '';
    const priceText =
      Object.keys(filtersSelected.price).length > 0
        ? `${priceTextLang}: ${formatPrice(filtersSelected.price.min, language) || ''} ${
            filtersSelected.price.max && filtersSelected.price.min ? ' - ' : ''
          } ${formatPrice(filtersSelected.price.max, language) || ''}`
        : '';
    const bedroomsText =
      filtersSelected.bedroomsMin > 0
        ? `${bedroomsLang}: ${filtersSelected.bedroomsMin} ${moreText}`
        : '';
    return [
      { filter: 'properties', text: propertiesText },
      { filter: 'locations', text: locationsText },
      { filter: 'regions', text: regionsText },
      { filter: 'features', text: featuresText },
      { filter: 'bedrooms', text: priceText },
      { filter: 'price', text: bedroomsText },
    ];
  };

  const formatPrice = (price, language) => {
    const languages = {
      es: 'es-ES',
      en: 'en-US',
      de: 'de-DE',
    };
    const formatting_options = {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
    };
    const lang = languages[language] || languages[0];
    return price ? price.toLocaleString(lang, formatting_options) : '';
  };

  const getFiltersFromUrl = (url) => {
    const regex = /filter=(.*)/;
    const match = url ? url.match(regex) : null;
    if (match && match[1]) {
      const decode = decodeURIComponent(match[1]);
      return JSON.parse(decode);
    }
    return {};
  };

  function getItemsSelected(items, AlItems) {
    const results = items.map((id) => {
      const result = AlItems.find((elm) => elm.id === id);
      return result ? result.name : null;
    });
    return results.join(', ');
  }

  return {
    getTexts,
    bedroomsOptions,
    pricesOptions,
    filtersSubscribe,
    getFiltersFromUrl,
  };
};

export default useFilters;
