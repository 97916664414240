import get from 'lodash.get';
import en from './en.json';
import es from './es.json';
import de from './de.json';

const defaultLanguage = 'en';
const languages = {
  en,
  es,
  de,
};

export function translate(textKey, userLang) {
  const selectedLanguage = languages[userLang] || languages[defaultLanguage];
  return get(selectedLanguage, textKey, textKey);
}
