import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ButtonToggle = React.forwardRef(function ButtonToggle(
  { children, onClick, iconLeft, iconRight, alignLeft = false },
  ref,
) {
  return (
    <Button
      variant="ligth"
      className="toggle-icon-button"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <div
        className={`d-flex ${
          alignLeft ? 'justify-content-start' : 'justify-content-between'
        } align-items-center`}
      >
        {iconLeft && (
          <FontAwesomeIcon icon={iconLeft} className={`text-primary ${alignLeft ? 'mr-2' : ''}`} />
        )}
        <span>{children}</span>
        {iconRight && <FontAwesomeIcon icon={iconRight} className="text-primary" />}
      </div>
    </Button>
  );
});

export default ButtonToggle;
