import React, { useState, useEffect, useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { translate } from '../../data/languages/languageUtils';
import useFilters from '../../hooks/useFilters';
import MinMaxSelect from './../sharedComponents/MixMaxSelect';
import Select from './../sharedComponents/Select';
import MultiSelectDropdown from './../sharedComponents/MultiSelectDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faSearch } from '@fortawesome/free-solid-svg-icons';
import getPropertiesFilter from './../../api/getPropertiesFilter';
import './search-filter-widget.scss';

export const defaultFilter = {
  order: 'date',
  orderDirection: 'desc',
};

function SearchFilterWidget({ language, domainUrl, direction }) {
  const domain = domainUrl
    ? `${domainUrl}/properties`
    : 'https://www.mallorcaproperty.net/properties';
  const [formState, setFormState] = useState(defaultFilter);
  const [filtersExpanded, setFiltersExpanded] = useState(false);
  const [filters, setFilters] = useState({});
  const { pricesOptions, bedroomsOptions } = useFilters(language);
  const selectPlaceholder = 'home.filters.select';
  const verticalClasses = direction === 'vertical' ? 'vertical-widget' : 'horizontal-widget';
  const classesInput =
    direction === 'vertical'
      ? 'col-md-12 col-lg-12 col-xs-12 mt-3'
      : 'col-xl-3 col-lg-3 col-md-6 mt-3';
  const fetchFilters = useCallback(async (lang) => {
    try {
      const allFilters = await getPropertiesFilter(lang);
      setFilters(allFilters);
    } catch (error) {
      console.log('error: ', error);
    }
  }, []);

  useEffect(() => {
    fetchFilters(language);
  }, [language]);

  const FilterButtons = () => (
    <div className="filter-buttons">
      <a
        className="btn btn-yellow search-button"
        target="_blank"
        rel="noreferrer"
        href={`${domain}?filter=${encodeURIComponent(JSON.stringify(formState))}`}
      >
        {translate('home.filters.searchNow', language)}
      </a>
      <button
        className="btn clear-button"
        onClick={() => {
          setFormState(defaultFilter);
        }}
      >
        {translate('home.filters.clear', language)}
      </button>
    </div>
  );

  return (
    <div className="bootstrap-widget">
      <div className={`${verticalClasses} search-filters`}>
        <div className="row-filters row">
          <div className={`${classesInput} input-filter`}>
            <MultiSelectDropdown
              placeholder={showOptionsSelected(
                getSelectedOptions(filters.propertyLocations, formState.propertyLocations),
                language,
                selectPlaceholder,
              )}
              label={translate('home.filters.searchByLocation', language)}
              icon={faCaretDown}
              options={filters.propertyLocations}
              selectedOptions={getSelectedOptions(
                filters.propertyLocations,
                formState.propertyLocations,
              )}
              onChange={(value) =>
                setFormState({ ...formState, propertyLocations: value.map((val) => val.id) })
              }
            />
          </div>
          <div className={`${classesInput} input-filter`}>
            <MultiSelectDropdown
              placeholder={showOptionsSelected(
                getSelectedOptions(filters.propertyTypes, formState.propertyTypes),
                language,
                selectPlaceholder,
              )}
              label={translate('home.filters.propertyType', language)}
              icon={faCaretDown}
              options={filters.propertyTypes}
              selectedOptions={getSelectedOptions(filters.propertyTypes, formState.propertyTypes)}
              onChange={(value) =>
                setFormState({ ...formState, propertyTypes: value.map((val) => val.id) })
              }
            />
          </div>
          <div className={`${classesInput} input-filter`}>
            <MinMaxSelect
              options={pricesOptions}
              min={formState.price?.min}
              max={formState.price?.max}
              minLabel={translate('home.filters.minPrice', language)}
              maxLabel={translate('home.filters.maxPrice', language)}
              onChange={(value) => setFormState({ ...formState, price: value })}
            />
          </div>
          {direction !== 'vertical' && (
            <div className="input-filter col-xl-3 col-lg-3 col-md-6 mt-3 search-buttons">
              <FilterButtons />
            </div>
          )}
        </div>
        {filtersExpanded && (
          <div className="row-filters row">
            <div className={`${classesInput} input-filter`}>
              <MultiSelectDropdown
                placeholder={showOptionsSelected(
                  getSelectedOptions(filters.propertyRegions, formState.propertyRegions),
                  language,
                  selectPlaceholder,
                )}
                label={translate('home.filters.searchByRegion', language)}
                icon={faCaretDown}
                options={filters.propertyRegions}
                selectedOptions={getSelectedOptions(
                  filters.propertyRegions,
                  formState.propertyRegions,
                )}
                onChange={(value) =>
                  setFormState({ ...formState, propertyRegions: value.map((val) => val.id) })
                }
              />
            </div>
            <div className={`${classesInput} input-filter`}>
              {filters.minMaxBedrooms && (
                <Select
                  options={bedroomsOptions}
                  value={formState.bedrooms?.min}
                  label={translate('home.filters.minBedrooms', language)}
                  onChange={(value) => {
                    setFormState((prevState) => ({
                      ...prevState,
                      bedrooms: { min: value },
                    }));
                  }}
                />
              )}
            </div>
            <div className={`${classesInput} input-filter`}>
              <MultiSelectDropdown
                placeholder={showOptionsSelected(
                  getSelectedOptions(filters.propertyFeatures, formState.propertyFeatures),
                  language,
                  selectPlaceholder,
                )}
                label={translate('home.filters.propertyFeatures', language)}
                icon={faCaretDown}
                options={filters.propertyFeatures}
                selectedOptions={getSelectedOptions(
                  filters.propertyFeatures,
                  formState.propertyFeatures,
                )}
                onChange={(value) =>
                  setFormState({ ...formState, propertyFeatures: value.map((val) => val.id) })
                }
              />
            </div>
            <div className={`${classesInput} input-filter`}>
              <Form.Control
                type="text"
                placeholder={translate('home.filters.keywordOrReference', language)}
                onChange={(e) => {
                  setFormState({ ...formState, reference: e.target.value });
                }}
                value={formState.reference}
              />
            </div>
          </div>
        )}
        <div className="expand-filters row mt-2">
          <div
            className="expand-action"
            onClick={() => {
              setFiltersExpanded(!filtersExpanded);
            }}
          >
            <FontAwesomeIcon icon={faSearch} size="sm" color="#b8a196" />
            {translate(
              filtersExpanded ? 'home.filters.lessFilters' : 'home.filters.moreFilters',
              language,
            )}
            <FontAwesomeIcon
              icon={filtersExpanded ? faCaretDown : faCaretUp}
              size="lg"
              color="#b8a196"
            />
          </div>
        </div>
        <div
          className={`${
            direction !== 'vertical' ? 'search-buttons-responsive' : 'search-buttons-vertical'
          } input-filter col-lg-12`}
        >
          <FilterButtons />
        </div>
      </div>
    </div>
  );
}

function showOptionsSelected(selectedFeatures, language, keyText) {
  let text = translate(keyText, language);
  if (selectedFeatures && selectedFeatures.length) {
    if (selectedFeatures.length === 1) {
      text = selectedFeatures[0].name;
    } else {
      text = selectedFeatures.length + ' ' + translate('home.filters.selected', language);
    }
  }
  return text;
}

function getSelectedOptions(allItems, itemsSelected) {
  return allItems && allItems.length > 0 && itemsSelected && itemsSelected.length > 0
    ? allItems?.filter((propertyFeature) =>
        itemsSelected.some((selectedId) => propertyFeature.id == selectedId),
      )
    : [];
}

export default SearchFilterWidget;
