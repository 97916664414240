import axios from 'axios';
import constants from '../constants';

async function getPropertiesFilter(lang) {
  const requirements = {
    lang,
  };
  const response = await axios.get(
    `${constants.apiUrlV1}/properties/suggest/filters?requirements=${encodeURIComponent(
      JSON.stringify(requirements),
    )}`,
  );
  return response.data;
}

export default getPropertiesFilter;
