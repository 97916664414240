import React from 'react';
import { Form } from 'react-bootstrap';

export default function SearchDropdownMenu({
  innerRef,
  style,
  className,
  labeledBy,
  children,
  value,
  setValue,
}) {
  return (
    <div ref={innerRef} style={style} className={className} aria-labelledby={labeledBy}>
      <div className="px-2">
        <Form.Control
          autoFocus
          placeholder="Search"
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
      </div>
      <ul className="list-unstyled">{children}</ul>
    </div>
  );
}
