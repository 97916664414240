import React, { useEffect, useState } from 'react';

const MinMaxSelect = ({ options, minLabel, maxLabel, min, max, onChange }) => {
  const [minValue, setMinValue] = useState(min);
  const [maxValue, setMaxValue] = useState(max);

  useEffect(() => {
    const minValue = min ? min : '';
    const maxValue = max ? max : '';
    setMinValue(minValue);
    setMaxValue(maxValue);
  }, [min, max]);

  const handleMinChange = (event) => {
    const value = getValue(event);
    setMinValue(value);
    if (maxValue !== null && value > maxValue) {
      setMaxValue(value);
    }
    const params = getMinMaxParams(value, maxValue);
    onChange && onChange(params);
  };

  const handleMaxChange = (event) => {
    const value = getValue(event);
    setMaxValue(value);
    if (minValue !== null && value < minValue) {
      setMinValue(value);
    }
    const params = getMinMaxParams(minValue, value);
    onChange && onChange(params);
  };

  function getValue(event) {
    const value = parseInt(event.target.value);
    return Number.isInteger(value) ? value : '';
  }

  function getMinMaxParams(min, max) {
    const obj = {};
    if (min) {
      obj.min = min;
    }
    if (max) {
      obj.max = max;
    }
    return obj;
  }

  return (
    <div className="min-max-component">
      <select value={minValue} onChange={handleMinChange}>
        <option value="">{minLabel}</option>
        {options.map((option) => (
          <option
            key={option.value}
            value={option.value}
            disabled={maxValue === '' ? false : option.value > maxValue}
          >
            {option.text}
          </option>
        ))}
      </select>

      <select value={maxValue} onChange={handleMaxChange}>
        <option value="">{maxLabel}</option>
        {options.map((option) => (
          <option
            key={option.value}
            value={option.value}
            disabled={minValue === '' ? false : option.value < minValue}
          >
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
};

export default MinMaxSelect;
